.layout {
  height: 100%;
  padding: 40px;
  max-width: 560px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 992px) {
    min-height: auto;
    height: auto;
    margin: 40px 16px;
    padding: 0;
    max-width: initial;
    flex-direction: row;
    flex: 1 1 auto
  }
}

.link {
  margin-top: 32px;
}