.card {
  padding: 16px 26px;
  margin: 40px;
  max-width: 680px;
}

.cardHeaderMb {
  margin-bottom: 60px;
}

.cardTitleMb {
  margin-bottom: 42px;
}

.cardContentMb {
  margin-bottom: 111px;
}