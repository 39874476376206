.card {
  padding: 40px 0;
  width: 100%;
  border-radius: 0;
}

.cardHeaderMb {
  margin-bottom: 40px;
}

.cardTitleMb {
  margin-bottom: 24px;
}

.stepTitleMb {
  margin-bottom: 16px;
}
